.new_shipment_cont {
    margin-top: 83px;
    padding: 40px;
    width: 100%;
}

.new_shipment_cont h2 {
    color: #333;
}

.new_shipment_content_cont input {
    background: #FFF;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
}

.new_shipment_content_cont input::placeholder {
    color: #8B8B8B;
}

.tags_input_b {
    max-width: 776px;
    margin-left: 25px;
}

.new_shipment_input_block {
    width: 100%;
    border-radius: 12px;
    border: 1px solid #D1D1D1;
    padding: 12px 16px;
    position: relative;
}

.new_shipment_input_block img {
    margin-right: 10px;
}

.new_shipment_content_cont input {
    /*margin-left: 10px;*/
}

.new_shipment_input_block textarea {
    width: 100%;
    height: 100%;
}

.ready_delivered_b {
    width: 100%;
}

.ready_input_b, .delivered_input_b {
    max-width: 663px;
    width: 100%;
}

.ready_input_b label {
    max-width: 663px;
    width: 100%;
}

.date_input_b img {
    margin-left: 24px;
    cursor: pointer;
}

.date_input_b_items {
    max-width: 615px;
    width: 100%;
}

/*.date_input_b_items div {*/
/*    max-width: 301px !important;*/
/*    width: 100%;*/
/*}*/

.react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: 100% !important;
}

.delivered_input_b_outside {
    max-width: 776px;
    width: 100%;
    margin-left: 25px;
}
.next_btn_b{
    margin-top: 60px;
}
.next_btn{
    border-radius: 32px;
    border: 1px solid  #73EDB8;
    background:  #73EDB8;
    max-width: 227px;
    width: 100%;
    padding: 16px;
}