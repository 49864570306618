.go_back_header {
    position: fixed;
    width: 100%;
    height: 75px;
    background-color: #F3FEF9;
    padding: 24px 42px;
    z-index: 99;
}
.go_back_header_btn_b{
    position: fixed;
    width: 20%;
    height: 75px;
    background-color: transparent;
    padding: 24px 42px;
    z-index: 99;
    cursor: pointer;
}

.go_back_header div {
    cursor: pointer;
}

.shipments_content_cont {
}

.shipments_content_cont .title_b h2 {
    color: #333;
    font-size: 32px;
    font-weight: 700;
    line-height: 41.6px; /* 130% */
}

.new_shipment_btn_b {
    max-width: 227px;
    width: 100%;
}

.new_shipment_btn_b button {
    padding: 16px;
    border-radius: 32px;
    border: 1px solid #73EDB8;
    background: #73EDB8;
    width: 100%;
}
.new_shipment_btn_b button img{
   margin-right: 10px;
}
.search_block_cont div{

}
.search_block_cont input{
    width: 100%;
    border-radius: 32px;
    background:  #F7F7F7;

}
.search_b{
    max-width: 489px;
    width: 100%;
}
.calendar_b, .delivery_b{
    max-width: 260px;
    width: 100%;
}
.search_b,.calendar_b, .delivery_b {
    position: relative;
    border-radius: 32px;
    background:  #F7F7F7;
    padding: 16px 16px 16px 46px;
    margin-right: 24px;
}
.search_b img, .delivery_b img, .calendar_b img {
    position: absolute;
    top: 23%;
    left: 4%;
}
.search_b img, .delivery_b img {
    position: absolute;
    top: 25%;
    left: 5%;
}
.calendar_b img {
    position: absolute;
    top: 26%;
    left: 5%;
}

.address_from, .address_to{
    width: 82px;
    border-radius: 32px;
    background:  #F7F7F7;
   padding: 16px;
    margin-right: 24px;
}
.filtration_btn{
    border-radius: 32px;
    border: 1px solid  #73EDB8;
    background: #FFF;
    padding: 16px;
    max-width: 260px;
    width: 100%;
    color: #333;
}
.filtration_btn img{
    margin-right: 10px;
}
.reset_filter_btn{
    text-decoration: underline;
    cursor: pointer;
}
.reset_filter_btn img{
margin-left: 12px;
}
.add_icon, .attach_icon {
    width: 24px;
    height: 24px;
}
.attach_icon{
    margin-left: 12px;
}
