.home_info_block_cont {
    margin-top: 100px;
}

.warehouse_cont, .couriers_cont, .pick_up_cont {
    width: 100%;
}

.warehouse_cont_block1, .warehouse_cont_block2 {
    max-width: 744px;
    width: 100%;
}

.warehouse_cont_block2 {
    margin-left: 32px;
}
.warehouse_cont_block1 img, .warehouse_cont_block2 img {
    width: 100%;
}
.couriers_cont, .pick_up_cont{
    margin-top: 110px;
}