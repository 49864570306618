.way_b {
    max-width: 600px;
    width: 100%;
}

.way_b p {
    color: #8B8B8B;
}

.way-vertical-slider {
    max-width: 580px !important;
    width: 100%;
}

.way_ranger_slider {
    margin-right: 0px !important;
    margin-bottom: 40px;
}

.way_ranger_slider .vertical-slider .example-track {
    margin-right: 0px !important;
}

.steps_container {
    margin-right: 0px !important;
    width: 100%;
    max-width: 600px;
}

.steps_container .steps_item {
    margin-right: 12px !important;
    min-width: 80px;
}

.steps_container .steps_item:last-child {
    margin-right: 0px !important;
}

.steps_items_cont .steps_date_item {
    margin-right: 0px !important;
    text-align: center;
}

.steps_items_cont .steps_date_item h3 {
    min-width: 80px;
    text-align: left;
}

.local_offer_icon {
    position: absolute;
    left: 10px;
    top: 25%;
    margin-right: 24px;
}

.transport_book_goods_block2 .input_block {
    border-radius: 12px;
    border: 1px solid #D1D1D1;
    padding: 12px 16px 12px 38px;
    position: relative;
    height: 62px;
}

.local_offer_item_b {
    width: max-content !important;
    padding: 8px;
    border-radius: 8px;
    background: #DFFBEE !important;
    cursor: pointer;
    margin-right: 4px;
}

.local_offer_item_b p {
    margin-right: 10px;
}
