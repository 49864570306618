.messenger_cont {
    border-radius: 8px;
    border: 1px solid #D1D1D1;
    background: #FFF;
    padding: 24px 32px;
}

.messenger_block_item {
    margin-bottom: 24px;
}


.send_message_block {
    width: 100%;
}
.messenger_block_item_img_b{
  margin-right: 14px;
}
.messenger_block_item_img_b img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.send_message_block input {
    border-radius: 8px;
    border: 1px solid #E3E3E3;
    background: #FFF;
    padding: 12px 16px;
    height: max-content;
}


.message_send_btn {
    background: #73edb8;
    padding: 12px 16px;
    margin-left: 24px;
}