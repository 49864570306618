.tabs_block {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-left: 3%;
}

.tab {
    padding: 8px 34px;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px; /* 150% */
}

.tab_active {
    background: white !important;
    border-top: 1px solid #E3E3E3;
    border-bottom: 1px solid white;
    padding: 8px 34px;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px; /* 150% */
    z-index: 12;
    position: relative;
}

.tab_active:after {
    content: '';
    position: absolute;
    left: 0px;
    top: 0%;
    height: 100%;
    border-left: 1px solid #E3E3E3;
}

.tab_active:before {
    content: '';
    position: absolute;
    right: 0px;
    top: 0%;
    height: 100%;
    border-right: 1px solid #E3E3E3;
}

.showTab {
    display: block;
}

.hideTab {
    display: none;
}

.settings_container {
    margin: 40px auto;
    padding: 65px;
    width: 100%;
    max-width: 1180px;
}

.settings_content_container {
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    padding: 40px;
    margin-top: -1px;
}

.settings_tab_content {
    width: 100%;
}

.settings_tab_content_1 {
    max-width: 454px;
    width: 100%;
    margin-right: 32px;
}

.settings_tab_content_1 div {
    border-radius: 8px;
    border: 1px solid #D1D1D1;
    background: #FFF;
    width: 100%;
}

.settings_tab_content_1 div input {
    max-width: 454px;
    width: 100%;
    padding: 12px 16px;
}

.settings_tab_content_2 {
    max-width: 600px;
    width: 100%;
}

.settings_tab_content_2 label {
    margin-left: 5px;
    color: #131313;
    font-size: 14px;
    font-style: normal;
    line-height: 21px; /* 150% */
}

.dropdown_div {
    width: 100%;
}

.settings_tab_content_2 .dropdown_block {
    border-radius: 8px;
    border: 1px solid #D1D1D1;
    background: #FFF;
    width: 100%;

}

.settings_tab_content_2 .dropdown_block input {
    width: 100%;
    padding: 12px;
}

.save_preferences_btn_b button {
    border-radius: 8px;
    border: 1px solid #73EDB8;
    background: #73EDB8;
    padding: 12px 16px;
}

.dropdown_div_header div {
    text-align: left;
    justify-content: left !important;
    align-items: unset !important;
}

.notification_tab_content_1 {
    max-width: 830px;
    width: 100%;
}

.notification_tab_content_1 .dropdown_div {
    max-width: 410px;
    width: 100%;
    margin-right: 12px;

}

.notification_tab_content_1 .dropdown_div .dropdown_block .dropdown {
    max-width: 400px;
    width: 100%;
    margin-top: 16px;

}

.notification_tab_content_1 .dropdown_div .dropdown_block .dropdown_header {
    border: 1px solid #D1D1D1;
    padding: 12px;

}

.notification_tab_content_1 .dropdown_div .dropdown_block .dropdown_header input {
    width: 100%;
}

.notification_tab_content_2 {
    max-width: 235px;
    width: 100%;
    margin-left: 12px;
}

.notification_tab_content_2_inner:first-child {
    margin-top: 4px;
}

.notification_tab_content_2_inner .export_b {
    margin-top: 5px;
    width: 50px;
    justify-content: center;
}

.notification_tab_content_2_inner .import_b {
    margin-top: 5px;
    width: 50px;
    justify-content: center;
}
.notification_tab_content_2_inner .btn_delete{
    border-radius: 8px;
    border: 1px solid #E4232B;
    color: red;
    padding: 12px 16px;
    width: 85px;
}
.notification_tab_content_2_inner .btn_new{
    border-radius: 8px;
    padding: 12px 16px;
    width: 85px;
}
.notification_tab_content_2_1 label{
    width: 50px;
    margin-right: 24px;
}
