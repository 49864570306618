.back_pic_b {
    margin-top: 144px;
    background: #00180B;
    width: 100%;
    padding: 126px 244px 154px 34px;
}

.home_back_pic_b1 {
    max-width: 828px;
    width: 100%;
}

.home_back_pic_b1 img {
    width: 100%;
}

.home_back_pic_b2 {
    max-width: 744px;
    width: 100%;
    margin-left: 32px;
}

.inputs_block_container {
    width: 100%;
}

.inputs_block {
    width: 100%;
}

.inputs_block_item {
    max-width: 356px;
    width: 100%;
    position: relative;
}

.inputs_block_item label {
    margin-bottom: 12px;
}

.inputs_block_item select {
    max-width: 356px;
    width: 100%;
    border-radius: 32px;
    border: 1px solid #000;
    background: #FFF;
    padding: 16px 18px;
}

.inputs_block_item input {
    max-width: 356px;
    width: 100%;
    border-radius: 32px;
    border: 1px solid #000;
    background: #FFF;
    padding: 16px 60px 16px 18px;
}

.inputs_block_item span {
    position: absolute;
    right: 20px;
    color: #646464;
    top: 27%;
}

.inputs_block_item:first-child {
    margin-right: 32px;
}