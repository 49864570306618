.shipment_items_cont {
    border-radius: 8px;
    border: 1px solid #E3E3E3;
    background: #FFF;
    width: 100%;
}

.shipment_items_cont_table_header {
    padding: 24px 45px;
    border-bottom: 1px solid #E3E3E3;
}

.shipment_items_cont_table_header p {
    min-width: 107px;
    /*width: 100%;*/
    margin-right: 16px;
}

.shipment_items_cont_table_header_b1 {
    max-width: 650px;
    width: 100%;
}

.shipment_items_cont_table_header_b2 {
    max-width: 510px;
    width: 100%;
}

.shipment_items_cont_table_item_items_b1 {
    max-width: 777px;
    width: 100%;
}

.shipment_items_cont_table_item_items_b2 {
    max-width: 580px;
    width: 100%;
}

.shipment_items_cont_table_header p:last-child {
    max-width: unset;
    margin-right: 0px;
}

.shipment_items_cont_scroll {
    overflow-y: auto;
    height: 900px;
    overflow-x: hidden;
   padding-right: 30px;
}

.shipment_items_cont_table_content {
    /*padding: 12px  16px 24px 12px;*/
    padding: 0px 0px 12px;


}

.shipment_items_cont_table_item_item {
    width: max-content;
    margin: 0px 16px 12px 0px;
    text-align: left;
    padding: 12px 7px 12px 12px;
}

.shipment_items_cont_table_item {
    padding-bottom: 18px;
}

.shipment_items_cont_table_item:hover {
    background: #F3FEF9;
}

.shipment_items_cont_table_item_item .input_checkbox {
    width: 20px;
    height: 20px;
    margin-top: 5px;
}

.shipment_items_cont_table_item:first-child {
    margin-top: 0px !important;
}

.shipment_items_cont_table_item_item p {
    margin-top: 0px !important;
    text-align: left;
}

.state_block {
    position: relative;
}

.state_block img {
    position: absolute;
    margin-top: 10px;
    left: -39%;
}

.state_block p {
    position: absolute;
    margin-top: 22px;
    left: 0%;
}

.attach_icon_b {
    margin-top: 78px;
}
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #b3f8db ;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #73edb8;
}