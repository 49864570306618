.dropdown {
    position: relative;
}

.dropdown_content {
    position: absolute;
    margin-top: 0px;
    border: 1px solid #D1D1D1;
    border-top: none;
    width: 100%;
    max-width: 600px;
    background: white;
    max-height: 200px;
    overflow-y: auto;
    z-index: 99;
}
.dropdown_content_item{
    text-align: left;
    padding: 10px;
    cursor: pointer;
}
.dropdown_content_item:hover{
   background: #F3FEF9;
}
