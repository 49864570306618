.pricing_cont {
    background: #00180b;
    margin-top: 144px;
    padding: 80px 0;
}

.pricing_items_cont {
    align-items: unset !important;
}

.price_desc {
    max-width: 615px;
    width: 100%;
    margin: 16px auto 64px;
}

.pricing_item {
    border-radius: 20px;
    border: 1px solid #E3E3E3;
    background: #00180B;
    box-shadow: 5px 11px 4px 0px rgba(223, 251, 238, 0.20);
    padding: 32px;
    max-width: 357px;
    width: 100%;
    height: max-content;
}

.offer_b {
    border-radius: 32px;
    border: 1px solid #73EDB8;
    height: 35px;
    display: flex;
    justify-content: center;
    max-width: 155px;
    width: 100%;
    margin-left: 16px;
}

.offer_b img {
    margin-right: 10px;
}

.features_block {
    border-top: 1px solid white;
}