.footer_cont{
    width: 100%;
    background: #131313;
    padding: 20px 0;
}
.footer_cont a{
    text-decoration: none;
}
.footer_cont a img{
   margin-right: 4px;
}