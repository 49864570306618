.incoterms_customers_b{

}

.customer_ref_b{
    border-radius: 8px;
    border: 1px solid #E3E3E3;
    background: #FFF;
    padding: 12px;
    max-width: 776px;
    width: 100%;
    margin-left: 25px;
}
.incoterms_b{
    border-radius: 8px;
    border: 1px solid #E3E3E3;
    background:  #FFF;
    padding: 12px;
    max-width: 776px;
    width: 100%;
}
