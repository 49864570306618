.temperature_range_slider {
    margin-top: 16px;
    width: 282px !important;
}

.css-eg0mwd-MuiSlider-thumb::after, .css-7drnjp::after {
    position: absolute;
    content: "";
    border-radius: 50%;
    width: 30px !important;
    height: 30px !important;
    background-color: #73edb8 !important;
}

.css-1gv0vcd-MuiSlider-track, .css-1t2bqnt {
    background: #DFFBEE !important;
    border-radius: 6px !important;
    border: none !important;
    height: 10px !important;
}
.css-14pt78w-MuiSlider-rail, .css-b04pc9{
    background: #c3f6df !important;
    border-radius: 6px !important;
    border: none !important;
    height: 10px !important;
}
.css-nnid7-MuiSlider-valueLabel, .css-3besu {
    background-color: transparent !important;
    color: black !important;
}
.css-nnid7-MuiSlider-valueLabel.MuiSlider-valueLabelOpen, .css-3besu.MuiSlider-valueLabelOpen  {
    -webkit-transform: translateY(120%) scale(1) !important;
    -moz-transform: translateY(120%) scale(1) !important;
    -ms-transform: translateY(120%) scale(1) !important;
}
