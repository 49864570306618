.collection_deliver_b{
    width: 100%;
    border-radius: 8px;
    border: 1px solid  #E3E3E3;
    background:  #FFF;
}
.collection_deliver_b_header{
    width: 100%;
    padding: 12px;
    border-bottom: 1px solid #E3E3E3;
}
.collection_deliver_b_content{
    width: 100%;
}
.collection_deliver_b_content .collection_block, .collection_deliver_b_content .deliver_block{
    width: 50%;
    padding: 12px;
}
.collection_deliver_b_content .collection_block{
    border-right: 1px solid #E3E3E3;
}
.additional_services_block{
    width: 100%;
    border: 1px solid #E3E3E3;
    border-top: none;
}
.additional_services_block1{
    padding: 24px;
    width: 50%;
    border-right: 1px solid #E3E3E3;
}
.additional_services_block2{
    width: 50%;
    padding: 24px;
}
.additional_services_block1 .checkbox_block, .additional_services_block2 .checkbox_block {
    white-space: nowrap;
    margin-right: 12px;
}
.additional_services_block1 .checkbox_block  input[type="checkbox"], .additional_services_block2 .checkbox_block input[type="checkbox"] {
   width: 20px;
    height: 20px;
    margin-right: 4px;
}