.type_select_b {
    width: 100%;
    min-width: 160px;
    margin-right: 12px;
}

.goods_b {
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #E3E3E3;
    background: #FFF;
}

.goods_b .new_shipment_input_block {
    margin-right: 12px;
}

.goods_b .adr_btn {
    border-radius: 8px;
    border: 1px solid #8B8B8B;
    padding: 12px 16px;
    margin-right: 12px;
}

.goods_b .adr_btn:hover {
    border-radius: 8px;
    border: 1px solid #73EDB8;
    background: #DFFBEE;
    font-weight: 600;
}

.goods_b .adr_btn_active {
    border-radius: 8px;
    border: 1px solid #73EDB8;
    background: #DFFBEE;
    font-weight: 600;
    margin-right: 12px;
    padding: 12px 16px;
}

.goods_b .add_btn {
    border-radius: 8px;
    background: #73EDB8;
    padding: 16px;
    font-size: 32px;
    line-height: 0px !important;
    height: 47px;
}

.goods_b .add_btn:hover {
    background: #1ed382;
}

.adr_b {
    max-width: 1450px;
    width: 100%;
}

.technical_desc_b {
    width: 596px !important;
}

.adr_b .delete_icon {
    cursor: pointer;
}

.temperature_add_value_b {
    max-width: 900px;
}

.temperature_add_value_b div:first-child {
    margin-right: 12px;
}

.temperature_add_value_b div label {
    white-space: nowrap;
}

.select_header {
    background: #FFF;
    padding: 10px;
    width: 100%;
    cursor: pointer;
    border: 1px solid #D1D1D1;
    border-radius: 8px;
}

.select_header_active {
    border: 1px solid #73EDB8;
    border-radius: 8px;
    background: #FFF;
    padding: 10px;
    width: 100%;
    cursor: pointer;
    border-bottom: none;
}

.select_dropdown {
    border: 1px solid #73edb8;
    height: 120px;
    overflow-y: auto;
    width: 100%;
}

.select_dropdown div {
    padding: 12px 16px;
    cursor: pointer;
    margin-right: 0px !important;
}

.select_dropdown div:hover {
    background-color: #F3FEF9;
}

.goods_b_item {
    padding-top: 12px;
    border-top: 1px solid  #D1D1D1;
}