.collection_b {
    border-radius: 8px;
    border: 1px solid #E3E3E3;
    background: #FFF;
    max-width: 776px;
    width: 100%;
}

.collection_b_1 {
    padding: 12px;
}

.collection_inputs_b .first_item {
    margin-right: 12px;
}

.collection_b input[type="checkbox"], .temperature_add_value_b input[type="checkbox"] {
    width: 20px !important;
    height: 20px !important;
    margin-right: 4px;
    border-radius: 4px;
    border: 1px solid #D1D1D1;
}

.additional_services_b {
    border-top: 1px solid #E3E3E3;
    background: #FFF;
    padding: 12px;
}

.additional_services_b p {
    color: #131313;
}

.additional_services_b p span {
    color: #8B8B8B;
}

.additional_services_b .checkbox_block {
    margin-right: 12px;
}

.transfer_data_icon {
    margin: 0px 2px;
}