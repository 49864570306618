.sign_up_cont {
    background: #00180B;
    margin-top: 144px;
    padding: 104px 0;
    /*height: 100%;*/
}

.sign_up_cont_img_b {
    max-width: 668px;
    width: 100%;
}

.sign_up_cont_img_b img {
    width: 100%;
}

.sign_up_cont_sign_up {
    max-width: 619px;
    width: 100%;
    margin-right: 32px;
}

.sign_up_cont_sign_up select {
    border-radius: 32px;
    border: 1px solid #000;
    background: #FFF;
    padding: 16px 18px;
}

.inputs_block select {
    width: 100%;
    border-radius: 32px;
    border: 1px solid #000;
    background: #FFF;
}

.input_block {
    width: 100%;
    border-radius: 32px;
    background: #FFF;
    padding: 16px 18px;
}

.input_block input {
    width: 100%;
}

.input_block_item {
    max-width: 292px;
    width: 100%;
    border-radius: 32px;
    background: #FFF;
    padding: 16px 18px;
}

.input_block_item:last-child {
    margin-left: 4px;
}

.input_block_item:first-child {
    margin-right: 4px;
}

.sign_up_cont_sign_up .input_block_item input {
    width: 100%;
}

.create_account_btn_b {
    width: 100%;
}

.create_account_btn {
    width: 100%;
    padding: 16px 18px;
    border-radius: 32px;
    background: #73EDB8;
}

/*.checkbox_block label {*/
/*    position: relative;*/
/*    padding-left: 51px;*/
/*}*/

/*.checkbox_block label span {*/
/*    text-decoration: underline;*/
/*}*/

/*.checkbox_block label:before {*/
/*    content: "";*/
/*    display: inline-block;*/
/*    width: 40px;*/
/*    height: 40px;*/
/*    border-radius: 10px;*/
/*    position: absolute;*/
/*    left: 0;*/
/*    background-color: #73edb8;*/
/*    box-shadow: inset 0px 2px 3px 0px rgba(0, 0, 0, .3), 0px 1px 0px 0px rgba(255, 255, 255, .8);*/
/*    cursor: pointer;*/
/*}*/

/*.checkbox_block input[type=checkbox] {*/
/*    display: none;*/
/*}*/

/*.checkbox_block .checkbox label:before {*/
/*    border-radius: 3px;*/
/*}*/

/*.checkbox_block input[type=checkbox]:checked + label:before {*/
/*    content: "\2713";*/
/*    text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);*/
/*    font-size: 30px;*/
/*    font-weight: 700;*/
/*    color: black;*/
/*    text-align: center;*/
/*    line-height: 30px;*/
/*}*/

.checkbox_block input{
    width: 40px;
    height: 40px;
    border-radius: 30px !important;
}

.checkbox_block p{
   margin-left: 11px;
}
.checkbox_block p span{
  text-decoration: underline;
}
.have_account_b {
    color: #E3E3E3;
}

.have_account_b span {
    text-decoration: underline;
    cursor: pointer;
    margin-left: 8px;
}
.have_account_b span:hover {
color: #44b681;
}
@media(max-width: 900px){
    .sign_up_cont_img_b {
      display: none;
    }
    .sign_up_container{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sign_up_cont_sign_up {
        margin-right: 0px;
    }
    .sign_up_container h2{
      text-align: center ;
    }
    .forget_pass_cont{
        height: 100%;
    }
}