.header_login_container {
    padding: 24px 0px;
    position: fixed;
    top: 0px;
    margin: auto;
    width: 100%;
    z-index: 999;
    background: white;
}
.header_login_content_mobile{
  display: none;
}

.logo {
    cursor: pointer;
    max-width: 200px;
    width: 100%;
    margin-right: 32px;
}

.header_btn {
    text-decoration: none;
    margin-right: 62px;
    cursor: pointer;
    white-space: nowrap;
    font-size: 20px;
    line-height: 30px;
}
.header_btn:hover, .header_btn_active:hover {
    transform: scale(1.03);
    color: #73EDB8;
}

.header_btn_active {
    text-decoration: none;
    margin-right: 62px;
    cursor: pointer;
    color: #73EDB8;
    white-space: nowrap;
    font-size: 20px;
    line-height: 30px;
}
.get_started_b{
    max-width: 220px;
    width: 100%;
}
.get_started_btn {
    width: 100%;
    padding: 16px 48px;
    border-radius: 32px;
    border: 1px solid #73EDB8;
    background:#73EDB8;
    font-size: 20px;
    line-height: 30px;
}
.menu_burger_block{
    display: none;
}
.dashboard_header_dropdown{
    position: relative;
}
.dashboard_header_dropdown_body{
    position: absolute;
    background: #00180b;
    right: 5%;
    top:120%;
    width: 200px;
    text-align: left;
    box-shadow: black;
    border: 1px solid green;
}
.dashboard_header_dropdown_body div{
    font-size: 16px;
    font-weight: 600;
    padding: 15px 10px 10px 10px;
    color: white;
}
.dashboard_header_dropdown_body div:hover{
    background: #73EDB8;
    color:  #00180b;
}

@media(max-width: 1280px){
    .header_btn, .header_btn_active{
        margin-right: 32px;
    }
    .get_started_btn{
        padding: 16px 32px;
    }
}
@media(max-width: 1024px){
    .header_btn, .header_btn_active{
        margin-right: 16px;
    }
    .get_started_btn{
        padding: 16px 24px;
    }
    .menu_burger_block{
        display: block;
        position: relative;
        margin-right: 32px;
    }
    .header_login_content{
        display: none;
    }
    .header_login_content_mobile{
        display: block;
        position: absolute;
        top: 70%;
        right: 22%;
        width: 300px;
        border: 1px solid #73edb8;
       background: #ffffff;
        padding:8px 0px;
    }

    .header_login_content_mobile .header_btn {
        text-decoration: none;
        cursor: pointer;
        white-space: nowrap;
        font-size: 20px;
        line-height: 30px;
        padding: 16px;
        text-align: left;
    }
    .header_login_content_mobile .header_btn:hover, .header_btn_active:hover {
        transform: scale(1.03);
        color: #73EDB8;
    }

    .header_login_content_mobile .header_btn_active {
        text-decoration: none;
        cursor: pointer;
        color: #73EDB8;
        white-space: nowrap;
        font-size: 20px;
        line-height: 30px;
        text-align: left;
        padding: 16px;
    }
}
