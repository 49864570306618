.sidebar {
    position: fixed;
    padding: 40px 40px 24px 40px;
    left: 0px;
    top: 0px;
    width: max-content;
    background-color: #00180b;
    height: 100%;
    z-index: 999;
    transition: transform 10ms linear;
}

.sidebar_logo {
    max-width: 150px;
}

.sidebar_navLinks_b {
    margin-top: 55px;
}

.sidebar_btn {
    text-decoration: none;
    cursor: pointer;
    white-space: nowrap;
    font-size: 20px;
    line-height: 30px;
    margin-top: 24px;
    color: white;
    text-align: left;
    display: flex;
    align-items: center;
}

.sidebar_btn:first-child:before {
    content: url("../../assets/images/icons/shipments_icon_active.png");
    margin-top: 5px;
    margin-right: 8px;
}

.sidebar_btn:nth-child(2):before {
    content: url("../../assets/images/icons/addresses_icon.png");
    margin-top: 5px;
    margin-right: 8px;
}

.sidebar_btn:nth-child(3):before {
    content: url("../../assets/images/icons/statitics_icon.png");
    margin-top: 5px;
    margin-right: 8px;
}

.sidebar_btn:nth-child(4):before {
    content: url("../../assets/images/icons/pricelist_icon.png");
    margin-top: 5px;
    margin-right: 8px;
}

.sidebar_btn_active:first-child:before {
    content: url("../../assets/images/icons/shipments_icon_active.png");
    margin-top: 5px;
    margin-right: 8px;
}

.sidebar_btn_active:nth-child(2):before {
    content: url("");
    margin-top: 5px;
    margin-right: 8px;
}

.sidebar_btn_active:nth-child(3):before {
    content: url("");
    margin-top: 5px;
    margin-right: 8px;
}

.sidebar_btn_active:nth-child(4):before {
    content: url("");
    margin-top: 5px;
    margin-right: 8px;
}


.sidebar_btn:hover, .sidebar_btn_active:hover {
    transform: scale(1.03);
    color: #73EDB8;
}

.sidebar_btn_active {
    text-decoration: none;
    cursor: pointer;
    color: #73EDB8;
    white-space: nowrap;
    font-size: 20px;
    line-height: 30px;
    margin-top: 24px;
    text-align: left;
    display: flex;
    align-items: center;
}

.sidebar_icon {
    position: absolute;
    top: 50%;
    left: 90%;
    cursor: pointer;
}
.help_b{
    margin-top: 48px;
}

.help_b div {
    margin-top: 12px;
}

.help_b div a {
    color: white;
    text-decoration: none;
    white-space: nowrap;
    display: flex;
    align-items: center;
}

.help_b div a img {
    margin-right: 4px;
}