.delivery_methods_items {
    border-radius: 4px;
    border: 1px solid #D1D1D1;
    width: max-content;
}

.delivery_methods_items div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 10px;
    width: 42px;
    height: 34px;
    cursor: pointer;
}

.delivery_methods_items div:hover {
    background-color: #73edb8;
}

.delivery_methods_items div:first-child img {
    width: 20px;
    height: 20px;
}

#tableShipment {
    border-collapse: collapse;
    border: 1px solid #E3E3E3;
    width: 100%;
}

#tableShipment td, #tableShipment th {
    padding: 24px;
    font-size: 16px;
    line-height: 24px;
}
#tableShipment td:first-child img{
   margin-right: 10px;
}
#tableShipment td:first-child p{
    margin-right: 8px;
}
 #tableShipment th {
    border-bottom: 1px solid #ddd;
    padding: 8px;
}

/*#tableShipment tr:nth-child(even) {*/
/*    background-color: #f2f2f2;*/
/*}*/
#tableShipment tr{
  cursor: pointer;
}

#tableShipment tr:hover {
    background-color: #DFFBEE;
}

#tableShipment th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: white;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
}
.COC_pic{
    margin-right: 4px;
}
.request_btn{
   padding: 12px 16px;
    border-radius: 8px;
    border: 1px solid #8B8B8B;
}
.book_now_btn{
    padding: 12px 16px;
    border-radius: 8px;
    border: 1px solid  #73EDB8;
}
.book_now_btn:hover{
 background: #73edb8;
}

.tabs_block {
    display: flex;
    justify-content: center;
    align-items: center;
}


.showTab {
    display: block;
}

.hideTab {
    display: none;
}
.tab_active{
    background: #73edb8;
}