.stay_with_cont{
    width: 100%;
    background: #00180B;
    padding: 80px;
    margin-top: 100px;
}
.stay_with_cont input{
    border-radius: 30px;
    background: #FFF;
    max-width: 356px;
    width: 100%;
    padding: 16px 18px;
    margin-right: 32px;
}

.stay_with_cont input::placeholder{

}