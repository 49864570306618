.transport_book_goods_block{
    border-radius: 8px;
    border: 1px solid  #E3E3E3;
    border-top: none;
    background:  #FFF;
    width: 100%;
}
.transport_book_goods_block1, .transport_book_goods_block2{
    width: 50%;
    padding: 12px;
}
.transport_book_goods_block1 div div{
    margin-right: 12px;
}