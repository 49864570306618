.how_works_cont{
    margin-top: 100px;
}
.how_works_cont_item1, .how_works_cont_item{
    max-width: 356px;
    width: 100%;
    margin-right: 32px;
}
.how_works_cont_item{
    padding: 40px 16px 40px 16px;
}
.how_works_cont_item img{
    max-width: 62px;
    max-height: 62px;
}


.how_works_cont_item:last-child{
    margin-right: 0px;
}