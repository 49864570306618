.carriers_container {
    margin: 100px auto 40px;
    max-width: 1214px;
    width: 100%;
    padding: 0px 16px;
}

.carriers_title_b button {
    margin-left: 16px;
    border-radius: 32px;
    border: 1px solid #73EDB8;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carriers_container .search_block {
    width: 100%;
    border-radius: 32px;
    background: #F7F7F7;
    padding: 16px;
    display: flex;
    justify-content: start;
    align-items: center;
}

.carriers_container .search_block input {
    width: 100%;
    margin-left: 10px;
}

.carriers_content_block {
    width: 100%;
    border-radius: 8px;
    border: 1px solid #E3E3E3;
}

.carriers_content_item {
    width: 100%;
    border-radius: 8px 8px 0px 0px;
    border-bottom: 1px solid #E3E3E3;
    padding: 16px 12px 16px 21px;
}

.carriers_content_item:hover {
    background: #F3FEF9;
}

.carriers_content_item_logo {
    width: 63px;
    margin-right: 24px;
}

.carriers_content_item_reg {
    color: #646464;
    margin-top: 4px;
    margin-bottom: 4px;
}

.carriers_content_item_reg span {
    background: #646464;
    height: 12px;
    width: 1px;
    margin: 0px 6px;
}

.carriers_content_item button {
    margin-top: 4px;
    border-radius: 10px;
    border: 1px solid #E3E3E3;
    padding: 8px 12px;
    width: max-content;
}

.carriers_content_item button img {
    margin-right: 4px;
}

.add_carrier_cont {
    border-radius: 8px;
    border: 1px solid #E3E3E3;
    background: #FFF;
    padding: 24px 48px;
}

.add_carrier_cont .save_btn {
    padding: 16px;
    border-radius: 32px;
    border: 1px solid #73EDB8;
    background: #73EDB8;
    max-width: 227px;
    width: 100%;
}

.carrier_search_item {
    border-radius: 8px;
    border: 1px solid #E3E3E3;
    background: #FFF;
}

.carrier_search_item .search_title {
    padding: 16px 12px;
    border-bottom: 1px solid #E3E3E3;

}
.carriers_checkboxes_b div{
    margin-right: 16px;
}
.carriers_checkboxes_b div input{
    margin-right: 5px;
}