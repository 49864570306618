.tracking_cont {
    background: #00180b;
    margin-top: 144px;
    padding: 40px;
}

.tracking_cont_b1 {
    max-width: 656px;
    width: 100%;
}

.tracking_cont_b1 img {
    width: 100%;
}

.tracking_cont_b2 {
    max-width: 615px;
    width: 100%;
    align-items: start;
    margin-left: 32px;
}

.submit_block {
    width: 100%;
}
.submit_block_input{
    max-width: 356px;
    width: 100%;
    margin-right: 32px;
}

.submit_block input {
    border-radius: 30px;
    background: #FFF;
    padding: 15px 18px;
   width: 100%;
}

.submit_block .submit_btn_b {
    max-width: 220px;
    width: 100%;
}
.submit_block .submit_btn_b button{
    width: 100%;
}