.logos_cont{
    padding: 40px 0;
    border-bottom: 1px solid #73EDB8;
}
.logos_cont_item{
    max-width: 250px;
    width: max-content;
    margin-right: 16px;
}
.logos_cont_item:hover{
   transform: scale(1.03);
}
.logos_cont_item img{
   width: 100%;
}
.logos_cont_item:first-child{
    margin-right: 0px;
}