.pricing_offers_desc_cont {
    margin: 100px auto;
}

.pricing_offers_desc_cont_b1 {
    max-width: 413px;
    width: 100%;
}

.pricing_offers_desc_cont_b1_items {
    margin-top: 80px;
}

.pricing_offers_desc_cont_b1_items div {
    padding: 16px 0;
    border-bottom: 1px solid #E3E3E3;
}
.pricing_offers_desc_cont_b2{
    max-width: 1100px;
    width: 100%;
}
.pricing_offers_desc_cont_b2_item {
    margin-left: 32px;
    max-width: 259px;
    width: 100%;
}

.pricing_offers_desc_cont_b2_item h2 {
    margin-top: 12px;
}

.pricing_offers_desc_cont_b2_item_content {
    margin-top: 80px;
}
.pricing_offers_desc_cont_b2_item_content div{
    padding: 16px;
    border-bottom: 1px solid #E3E3E3;
}