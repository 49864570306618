.dashboard_sidebar_icon{
    position: absolute;
    top: 50%;
    left: -1%;
    cursor: pointer;
}
.content_sidebar_open{
    margin-left: 260px;
}
.content_sidebar_close{
    width: 100%;
}
.content_sidebar_open .go_back_header{
    width: calc(100% - 260px);
}
.discussion_btn{
    border-top: 1px solid #E3E3E3;
    border-bottom: 1px solid white;
    padding: 8px 10px 0px 10px;
    margin-left: 30px;
    margin-top: -2%;
    position: absolute;
    z-index: 12;
    background: white;
}
.discussion_btn:after{
    content: '';
    position: absolute;
    left: 0px;
    top: 0%;
    height: 60%;
    border-left: 1px solid #E3E3E3;
}
.discussion_btn:before{
    content: '';
    position: absolute;
    right:0px;
    top: 0%;
    height: 60%;
    border-right: 1px solid #E3E3E3;
}
.discussion_btn img{
    margin-right: 4px;
}

#tableShipmentDetails {
    border-collapse: collapse;
    width: 100%;
}

#tableShipmentDetails td, #tableShipmentDetails th {
    padding: 24px;
    font-size: 16px;
    line-height: 24px;
    margin: 10px auto;
}
#tableShipmentDetails td:first-child img{
    margin-right: 10px;
}
#tableShipmentDetails td:first-child p{
    margin-right: 8px;
}
#tableShipmentDetails th {
    border-bottom: 1px solid #ddd;
    padding: 8px;
}

/*#tableShipmentDetails tr:nth-child(even) {*/
/*    background-color: #f2f2f2;*/
/*}*/
#tableShipmentDetails tr{
    cursor: pointer;
    border-bottom: 1px solid #E3E3E3;
}

#tableShipmentDetails tr:hover {
    background-color: #DFFBEE;
}

#tableShipmentDetails th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: white;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
}
.discussion_cont{
    border: 1px solid #E3E3E3;
    padding: 10px;
}
