.ranger_with_steps {
    max-width: 487px;
    width: 100%;
}

.ranger_with_steps input {
    max-width: 487px;
    width: 100%;
}

.circle1, .circle2 {
    background: #EEEEEE;
    width: 18px;
    height: 18px;
    border-radius: 50%;
}

.circle1 {

}

.circle1, .circle2 {
    background: #EEEEEE;
    width: 18px;
    height: 18px;
    border-radius: 50%;
}

.ranger_with_steps input[typw="range"] {
    background-color: #73EDB8 !important;
    overflow: hidden;
    z-index: 2;
}

.slidecontainer {
    width: 100%;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.slider:hover {
    opacity: 1;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    background: #73EDB8;
    cursor: pointer;
    border-radius: 50%;
}

.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #04AA6D;
    cursor: pointer;
}


.vertical-slider {
    max-width: 487px;
    width: 100%;
}

.example-mark {
    width: 18px;
    height: 18px;
    border: 2px solid #EEE;
    background-color: #EEE;
    cursor: pointer;
    border-radius: 50%;
    /*vertical-align: middle;*/
    /*margin: 0px calc(19px);*/
    bottom: calc(50% - 12px);
}

.example-mark.example-mark-active {
    border: 2px solid #73EDB8;
    background-color:#73EDB8;
}

.example-mark.example-mark-completed {
    border: 2px solid #73EDB8;
    background-color: #73EDB8;
}

.vertical-slider .example-track {
    /*left: 23px;*/
    height: 5px;
}

.example-track.example-track-0 {
    background: #73EDB8;
}

.example-track {
    position: relative;
    background: #ddd;
}

.example-track.example-track-0 {
    background: #73EDB8;
}
