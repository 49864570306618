.shipments_cont {
    margin-top: 40px;
    padding: 65px;
    width: 100%;
}

.shipments_cont h2 {
    color: #333;
    font-size: 32px;
    font-weight: 700;
    line-height: 41.6px; /* 130% */
}

.shipments_cont p {
    margin-top: 8px;
    color: #333;
}
.shipments_cont_content{
    border-radius: 20px;
    border: 1px solid #E3E3E3;
}
.shipments_cont_content_item {
    padding: 24px;
    border-bottom: 1px solid #E3E3E3;
    cursor: pointer;
}
.shipments_cont_content_item:last-child{
    border-bottom: none;
}
.shipments_cont_content_item:hover {
    border-radius: 20px 20px 0px 0px;
    background: #F3FEF9;
}


.shipments_cont_content_item_textB {
    margin-left: 60px;
}

.shipments_cont_content_item_textB p {
    margin-bottom: 8px;
}